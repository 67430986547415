<template>
	<div :class="themeClass" class="min-width1100 max-width1100 mar">
		<div class="padding-20" style="background-color: #F9F9F9;">
			<div style="height: 111px;" class="bagm flex-row-space-between-center padding-lr-20 margin-b-10">
				<div class="flex-row-align-center position-relative">
					<img src="../../assets/u255.png" class="img1">
					<img src="../../assets/u256.png" class="img2">
					<div class="flex-colum">
						<div class="tilp font-size18 font-weight700">可兑换积分</div>
						<div class="font-color-fff font-size25 font-weight700 text-center">{{allIntegral}}</div>
					</div>
				</div>
				<div class="btnwh2 theme-bg-color font-color-fff flex-row-center-center font-weight700 pointer" @click="popupShow = chooseIsOpen">
					选择店铺
				</div>
			</div>
			<div style="display: flex;">



				<div style="margin-right: 15px;width:322px;background-color: #fff;" class="box-four-picture-box flex-row-center-center">
					<img src="../../assets/u367.png" style="width: 274px;">
				</div>
				<div class="font-color-333 bg-fff padding-20 " style="width:723px">
					<div class="flex-row-align-center" style="margin-bottom: 15px;">
						<span class="font-size12 font-color-FF9900 font-weight700 margin-r-10">提示：消费积分兑换金币后，不可撤销</span>
						<span class="iconfont icon-wenhao font-color-FF9900 font-size16" @click="ratioShowClick"></span>
						<span class="margin-l-3 font-size12" v-if="ratioShow">兑换比例为1:{{ratio}}</span>
					</div>


					<div v-if="chooseShopList.length>0">
						<div v-for="(item,index) of chooseShopList" :key="index" style="width: 402px;">
							<div style="width: 100%;height: 15px;">
								<div style="float: left;width: 250px;" class="hide-text"> {{item.fShopName}} </div>
								<div style="text-align: right;width: 148px;float: right;">当前：{{item.fEndBalance}}积分</div>
							</div>
							<div class="egc-input flex-row-space-between-center padding-10 border-F2F2F2 margin-tb-10" style="width: 380px;">
								<input v-model.number="item.inputIntegral" style="width:290px;border: none;outline:none;" @blur="inputBlur(item,index)"
								 @input="inputBlur(item,index)" />
								<div class="color-theme pointer" @click="allExchange(item)">
									全部兑换
								</div>
							</div>
						</div>

						<div class="margin-b-5">
							可兑换金币： <span class="vcolors font-weight700 margin-r-5">{{exchangeGoldColns}}</span><span class="vcolors">金币</span>
						</div>
						<div class="btnco margion flex-row-center-center mtop40 pointer" @click="confirmExchange">
							<div class="btnco  btnwh theme-bg-color font-color-fff flex-row-center-center font-weight700">
								兑换
							</div>
						</div>
					</div>
					<div class="flex-column-center-center" v-else>
						<svg-icon icon-class="NoRightsAndInterests" />
						<div class="font-color-999">还没选择可兑换金币的店铺!</div>
					</div>
				</div>

			</div>
		</div>

		<el-dialog v-dialogDrag title="请选择可兑换金币的店铺" :visible.sync="popupShow" v-if="popupList.length >0" width="560px"
		 :close-on-click-modal="false" :close-on-press-escape="false">
			<div class="dialogScroll">
				<div class="egc-popup-item flex-row-space-between-center border-bottom-F2F2F2" v-for="(item,index) in popupList"
				 :key="index">
					<el-checkbox v-model="item.checked" @change="radioChange(index)"></el-checkbox>
					<div class="flex-row-space-between-center margin-l-10 width-fill">
						<div class="egc-popup-column-picture-box radius5 flex-row-center-center margin-r-10">
							<img class="egc-popup-column-picture" :src="item.fShopLogoPath" />
						</div>
						<div class="egc-popup-content flex-row-between-start">
							<div style="width: 444px;" class="hide-text margin-b-5">
								{{item.fShopName}}
							</div>
							<div class="color-theme">
								{{item.fEndBalance}} 积分
							</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="popupShow = false" class="btncla">取 消</el-button>
				<el-button type="primary" @click="popupConfirm" class="btncla">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				ratioShow: false, //兑换比例是否显示
				ratio: 0, //一个积分能兑换多少金币
				allIntegral: 0, //所有店铺总积分
				// 可总换金币
				exchangeGoldColns: 0,
				popupShow: false,
				// 弹窗数据
				popupList: [],
				chooseShopList: [], //选择了哪些店
				chooseIsOpen: false,
				onlyApi:false,
			};
		},
		mounted() {
			this.getPointSwapGoldCCionRatio();
			this.getMyPoint();
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			//获取兑换比例
			getPointSwapGoldCCionRatio() {
				this.ApiRequestPostNOMess('api/mall/ebactivity/point-swap-gold-cion-bill/get-point-swap-goldC-cion-ratio', {
					maxResultCount: 100,
					skipCount: 0
				}, ).then(res => {
					this.ratio = res.obj.ratio;
				}, error => {

				});
			},
			//获取总积分
			getMyPoint() {
				this.ApiRequestPostNOMess('api/mall/ebbalance/customer-point/get-my-point', {
					maxResultCount: 100,
					skipCount: 0
				}, ).then(res => {
					console.log('获取店铺兑换数据')
					console.log('res1', res);
					console.log('获取店铺兑换数据')
					for (var i = 0; i < res.obj.pointlist.length; i++) {
						if (res.obj.pointlist[i].fEndBalance > 0) {
							this.popupList.push(res.obj.pointlist[i]);
							console.log('this.popupList', this.popupList);
						}
					}
					this.allIntegral = res.obj.sumCustomerPoint;
					res.obj.pointlist.forEach((item) => {
						item.checked = false;
					});
					if (this.popupList.length > 0) {
						this.chooseIsOpen = true
					} else {
						this.chooseIsOpen = false
					}





				}, error => {

				});
			},
			// 弹窗选择
			radioChange(index) {
				console.log('111', this.popupList[index]);
				// this.popupList[index].checked = !this.popupList[index].checked;
				this.$forceUpdate()
			},
			// 弹窗确定
			popupConfirm() {
				let findData = this.popupList.find(findItem => findItem.checked == true);
				if (findData) {

				} else {
					this.$message('请选择要兑换金币的店铺');
					return;
				}
				this.chooseShopList = [];
				for (let item of this.popupList) {
					if (item.checked == true) {
						this.chooseShopList.push(item);
					}
				}
				this.chooseShopList.forEach((item) => {
					item.inputIntegral = null;
				});
				this.popupShow = false;
			},
			//兑换比例是否显示
			ratioShowClick() {
				this.ratioShow = !this.ratioShow;
			},
			//输入框失焦
			inputBlur(obj, index) {
				this.exchangeGoldColns = 0;
				console.log('obj', obj);
				console.log('index', index);
				if (parseInt(obj.inputIntegral) > obj.fEndBalance) {
					this.$message('不可大于总积分');
					this.chooseShopList[index].inputIntegral = null;
					this.exchangeGoldColns = 0;
					this.$forceUpdate();
				} else if (obj.fEndBalance == 0) {
					this.$message('您在此店铺没有积分');
				} else if (obj.fEndBalance >= parseInt(obj.inputIntegral) || obj.inputIntegral == '') {
					this.chooseShopList.forEach((item, index1) => {
						if (item.inputIntegral != null && item.inputIntegral != '') {
							this.exchangeGoldColns += parseInt(item.inputIntegral) * this.ratio;
						}
					});
				} else {
					this.exchangeGoldColns = 0;
				}
			},
			// 全部兑换
			allExchange(obj) {
				if (obj.fEndBalance == 0) {
					this.$message('您在此店铺没有积分');
					return;
				}
				this.exchangeGoldColns = 0;
				obj.inputIntegral = obj.fEndBalance;
				this.chooseShopList.forEach((item, index1) => {
					if (item.inputIntegral != null && item.inputIntegral != '') {
						this.exchangeGoldColns += parseInt(item.inputIntegral) * this.ratio;
					}
				});
			},
			// 确定兑换
			confirmExchange() {
				if (this.exchangeGoldColns == 0) {
					this.$message('请确定您要兑换多少金币');
					return;
				}
				let params = [];
				this.chooseShopList.forEach((item) => {
					console.log('item', item);
					if (item.checked && item.inputIntegral != 0) {
						let data = {
							fPointAmount: item.inputIntegral,
							fShopUnitID: item.fShopUnitID
						}
						params.push(data);
					} else {
						this.$message('请确定' + item.fShopName + '要使用多少积分兑换金币');
						return;
					}
				});


				this.$confirm('积分一经兑换，不可撤销！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(this.onlyApi){
						return
					}
					this.onlyApi = true
					this.ApiRequestPostNOMess('api/mall/ebactivity/point-swap-gold-cion-bill/init', {}, ).then(res => {
						console.log('获取初始编码', res);
						this.ApiRequestPostNOMess('api/mall/ebactivity/point-swap-gold-cion-bill/create', {
							fAppTypeID: this.fAppTypeID,
							fBillNumber: res.obj,
							detailList: params
						}, ).then(res => {
							console.log('res1', res);
							this.onlyApi = false
							this.$confirm('恭喜您成功兑换' + this.exchangeGoldColns + '金币', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'success'
							}).then(() => {
								this.$router.go(-1);
							})
						}, error => {
							this.onlyApi = false
						});
					}, error => {
						this.onlyApi = false
					});
				}).catch(() => {
						this.onlyApi = false
				});



			}
		}
	};
</script>

<style lang="scss" scoped="scoped">
	.svg-icon {
		width: 250px;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.mar {
		margin: 0 auto;
	}

	.img1 {
		// position: relative;
		width: 81px;
		height: 81px;
	}

	.img2 {
		position: absolute;
		width: 38px;
		height: 45px;
		left: 22px;
		top: 18px;
	}

	.tilp {
		color: #ffffcc;
	}

	.mtop40 {
		width: 159px;
		margin-top: 40px;
	}

	.btnco {
		background: #0173fe;
	}

	.btnwh {

		padding-top: 10px;
		padding-bottom: 10px;
	}

	.btnwh2 {
		color: #ff9900;
		background: #ffffcc;
		width: 120px;
		height: 40px;
	}

	.vcolors {
		color: #FF6600
	}

	.egc-content-confirm-btn-box {
		margin-top: 40px;
	}

	.egc-content-confirm-btn {
		width: 280px;
		height: 36px;
		border-radius: 60px;
	}

	.egc-popup-title {
		height: 45px;
		line-height: 45px;
	}

	.egc-popup-list {
		// height: 600rpx;
	}

	.egc-popup-item {
		height: 65px;
	}

	.egc-popup-column-picture-box {
		width: 40px;
		height: 40px;
		border: 1px solid #e4e4e4;
	}

	.egc-popup-column-picture {
		width: 30px;
		height: 30px;
	}

	.egc-popup-content {
		width: 444px;
	}

	.egc-popup-content-bottom {
		width: 100%;
	}

	.egc-popup-tab {
		min-width: 37px;
		height: 18px;
		line-height: 18px;
		text-align: center;
	}

	.egc-popup-confirm-btn {
		width: 350px;
		height: 36px;
		border-radius: 60px;
	}

	.bagm {
		background: url(../../assets/u300.jpg) no-repeat;
		background-size: 1060px 111px;
		// position: relative;
	}

	input[type="number"]:focus {
		outline: none;
	}

	input[type="number"] {
		border: 0;
	}

	.btncla {
		padding: 0;
		width: 56px;
		height: 32px;
	}

	/deep/ .el-dialog {
		border-radius: 5px;
	}

	/deep/ .el-dialog__wrapper {
		display: flex;
		align-items: center;
	}
</style>
